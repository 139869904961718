function Video (props) {
  if (!props.src) throw new Error('Src prop is missing')
  return (
    <video {...props}>
      <source src={props.src} />
    </video>
  )
}

export default Video
