import { Box, Flex, Image, Text } from '@chakra-ui/core'

const icons = {
  date: '/event-calendar.svg',
  time: '/event-time.svg',
  text: '/event-text.svg',
  link: '/event-link.svg'
}

const AttachmentWrapper = ({ type, children, ...rest }) => {
  return (
    <Box
      padding={type === 'date' || type === 'time' ? '7px 15px' : '15px'}
      background='#F2F5FF'
      borderRadius='5px'
      position='relative'
      {...rest}
    >
      <Flex
        textTransform='capitalize'
        fontSize='0.875rem'
        color='#3D5260'
        fontWeight='400'
        position='relative'
      >
        <Image src={icons[type]} alt={type} mr='6px' />
        {type}
      </Flex>
      {children}
    </Box>
  )
}

const AttachmentWrapperEditable = ({
  type,
  onDeleteClick,
  children,
  typeChildren,
  ...rest
}) => {
  return (
    <Box
      padding={type === 'date' || type === 'time' ? '7px 15px' : '15px'}
      background='#F2F5FF'
      borderRadius='5px'
      position='relative'
      {...rest}
    >
      <Flex
        textTransform='capitalize'
        color='#3D5260'
        fontWeight='400'
        position='relative'
      >
        <Image src={icons[type]} alt={type} mr='6px' />
        <Text mr='10px'>{type}</Text>
        {typeChildren}
        <Box
          position='absolute'
          right='0'
          fontSize='1.25rem'
          cursor='pointer'
          onClick={onDeleteClick}
        >
          <Image src='/delete.svg' alt='delete' />
        </Box>
      </Flex>
      {children}
    </Box>
  )
}

export { AttachmentWrapper, AttachmentWrapperEditable }
