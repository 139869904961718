import NextLink from 'next/link'
import { Link as ChakraLink, Image } from '@chakra-ui/core'
import theme from '../../theme'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const H1 = ({ children, id }) => {
  return (
    <>
      <h1 id={id}>{children}</h1>
      <style jsx>
        {`
          h1 {
            font-size: 2.8em;
            margin-top: 0;
            margin-bottom: 0.8571429em;
            line-height: 1;
            font-weight: 800;
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
        `}
      </style>
    </>
  )
}

const H2 = ({ children, id }) => {
  return (
    <>
      <h2 id={id}>{children}</h2>
      <style jsx>
        {`
          h2 {
            font-size: 1.8em;
            margin-top: 1.5555556em;
            margin-bottom: 0.8888889em;
            line-height: 1.1111111;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: 700;
          }
        `}
      </style>
    </>
  )
}

const H3 = ({ children, id }) => {
  return (
    <>
      <h3 id={id}>{children}</h3>
      <style jsx>
        {`
          h3 {
            font-size: 1.5em;
            margin-top: 1.6em;
            margin-bottom: 0.6666667em;
            line-height: 1.3333333;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: 700;
          }
        `}
      </style>
    </>
  )
}

const Ul = ({ children }) => (
  <ul>
    {children}
    <style jsx>
      {`
        ul {
          margin-top: 1.2em;
          margin-bottom: 1.2em;
          list-style: none;
          display: block;
          margin-left: 0;
          padding-left: 0;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
        }
      `}
    </style>
  </ul>
)

const Li = ({ children }) => (
  <li>
    {children}
    <style jsx>
      {`
        li {
          padding-left: 1.8em;
          display: list-item;
          position: relative;
          margin-top: 0.6em;
          margin-bottom: 0.6em;
          text-align: -webkit-match-parent;
        }

        li:before {
          content: "";
          position: absolute;
          background-color: #cbd5e0;
          border-radius: 50%;
          width: 0.375em;
          height: 0.375em;
          top: 0.6875em;
          left: 0.25em;
        }
      `}
    </style>
  </li>
)

const Code = ({ children, syntax }) => {
  return (
    <pre className={syntax ? ` ${syntax}` : ''}>
      <code>{children}</code>
      <style jsx>
        {`
          pre {
            background: #1d1f21;
            color: #f8f8f2;
            white-space: pre;
            overflow: auto;
            padding: 1.5rem;
            margin: 40px 0;
            border-radius: 3px;
            -webkit-overflow-scrolling: touch;
          }
          code {
            font-size: 14px;
            line-height: 20px;
          }
        `}
      </style>
    </pre>
  )
}

const P = ({ children }) => {
  return (
    <p>
      {children}
      <style jsx>
        {`
          p {
            margin-top: 1.2em;
            margin-bottom: 1.2em;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
        `}
      </style>
    </p>
  )
}

const Img = (props) => {
  return (
    <Zoom>
      <Image {...props}  />
    </Zoom>
  )
}

const Link = (props) => {
  if (!props.href) {
    throw new Error('Href is required in Link component!')
  }

  if (props.href.startsWith('/')) {
    return (
      <NextLink href={props.href}>
        <ChakraLink color='purpleBlue'>{props.children}</ChakraLink>
      </NextLink>
    )
  }

  return <ChakraLink target='__blank' href={props.href} color='purpleBlue'>{props.children}</ChakraLink>
}

const BlockQuote = (props) => (
  <>
    <blockquote>{props.children}</blockquote>
    <style jsx>
      {`
      blockquote {
          border-left: 5px solid ${theme.colors.primary};
          padding-left: 1rem !important;
          margin-left: 0px !important;
          margin-right: 0px !important;
          font-style: italic;
        }
      `}
    </style>
  </>
)

export const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  li: Li,
  ul: Ul,
  code: Code,
  p: P,
  img: Img,
  a: Link,
  blockquote: BlockQuote
}
