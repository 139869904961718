import Container from '../container'
import { MDXProvider } from '@mdx-js/react'
import { components } from './post-components'
import Navbar from '../navbar'
import { NextSeo } from 'next-seo'

const withPost = meta => ({ children }) => {
  return (
    <>
      <Navbar />
      <MDXProvider components={components}>
        <Container
          maxWidth='750px'
          fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
          lineHeight={['1.75', '1.8', '1.8', '1.8']}
        >
          <NextSeo
            title={meta.title}
            description={meta.description}
            twitter={{
              cardType: 'summary_large_image',
              site: '@HapenApp',
              handle: '@HapenApp'
            }}
            openGraph={{
              title: meta.title,
              description: meta.description,
              images: [
                {
                  url: meta.cover
                }
              ]
            }}
            siteName='RemindLink'
          />
          {children}
          <style jsx>
            {`
              @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

              html {
                font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont,
                  Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
                  Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
                  Noto Color Emoji;
                line-height: 1.5;
              }
              strong,
              b {
                font-weight: 600;
              }
            `}
          </style>
        </Container>
      </MDXProvider>
    </>
  )
}

export default withPost
