import { Text, Textarea } from '@chakra-ui/core'
import {
  AttachmentWrapper,
  AttachmentWrapperEditable
} from './attachment-wrapper'
import autosize from 'autosize'
import { useEffect, useRef } from 'react'

const TextAttachmentDisplay = ({ text, wrapperProps = {} }) => {
  return (
    <>
      <AttachmentWrapper
        type='text'
        fontSize='1rem'
        width='100%'
        fontWeight='400'
        mb='20px'
        {...wrapperProps}
      >
        <Text
          className='text-auto-area'
          maxLength='140'
          border='1px solid transparent'
          mt='2px'
          width='100%'
          lineHeight='1.47rem'
          borderRadius='5px'
          padding='0px'
          wordBreak='break-word'
          whiteSpace='pre-wrap'
        >
          {text}
        </Text>
      </AttachmentWrapper>
    </>
  )
}

const TextAttachmentEditable = ({
  value,
  onChange,
  onDelete,
  symbolCounter,
  ...props
}) => {
  const inputRef = useRef()
  useEffect(() => {
    autosize(inputRef.current)
    return () => {
      autosize.destroy()
    }
  }, [])
  return (
    <AttachmentWrapperEditable
      type='text'
      fontSize='1rem'
      width='100%'
      fontWeight='400'
      mb='20px'
      onDeleteClick={onDelete}
      typeChildren={symbolCounter}
    >
      <Textarea
        ref={inputRef}
        value={value}
        onChange={onChange}
        className='text-auto-area'
        maxLength='240'
        border='1px solid  #dbe2ea'
        marginTop='2px'
        resize='none'
        height='100%'
        width='100%'
        mt='8px'
        lineHeight='1.47rem'
        background='transparent'
        borderRadius='5px'
        padding='5px'
        style={{
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }}
        {...props}
      />
    </AttachmentWrapperEditable>
  )
}

export { TextAttachmentDisplay, TextAttachmentEditable }
