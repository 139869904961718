import { Box, Text, Image, Input, Flex } from '@chakra-ui/core'
import {
  AttachmentWrapper,
  AttachmentWrapperEditable
} from './attachment-wrapper'

const LinkAttachmentDisplay = ({
  url,
  title,
  description,
  imageUrl,
  wrapperProps = {}
}) => {
  const link = new URL(url)
  link.searchParams.append('ref', 'hapen.io')
  const urlWithHapenRef = link.toString()

  return (
    <>
      <AttachmentWrapper
        type='link'
        fontSize='1rem'
        width='100%'
        fontWeight='400'
        {...wrapperProps}
      >
        <Box
          as='a'
          href={urlWithHapenRef}
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          <>
            {title && (
              <Text lineHeight='1.35rem' mb='3px' mt='6px' fontWeight='600'>
                {title}
              </Text>
            )}
            {description && (
              <Text
                fontSize='0.875rem'
                lineHeight='1.1875rem'
                color='#101010'
                opacity='0.5'
                mb='5px'
              >
                {description}
              </Text>
            )}
          </>

          <Text
            cursor='pointer'
            opacity='1'
            fontSize='0.875rem'
            color='#708CFF'
            padding='0'
            background='transparent'
            height='initial'
            borderColor='transparent'
            fontWeight='500'
            wordBreak='break-word'
            _hover={{
              borderColor: 'transparent'
            }}
          >
            {url}
          </Text>
          {imageUrl && (
            <a
              href={urlWithHapenRef}
              target='_blank'
              rel='nofollow noopener noreferrer'
            >
              <Flex alignItems='center' justifyContent='center'>
                <Image
                  borderRadius='5px'
                  src={imageUrl}
                  maxWidth='100%'
                  mt='15px'
                />
              </Flex>
            </a>
          )}
        </Box>
      </AttachmentWrapper>
    </>
  )
}

const LinkAttachmentEditable = ({
  link,
  onChange,
  value,
  onDelete,
  ...props
}) => {
  return (
    <div>
      <AttachmentWrapperEditable
        onDeleteClick={onDelete}
        type='link'
        fontSize='1rem'
        width='100%'
        fontWeight='400'
        mb='20px'
      >
        <Box
          as='div'
          href='https://asdasd.com'
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          <Input
            onChange={onChange}
            value={value}
            fontSize='0.875rem'
            color='#708CFF'
            padding='5x'
            mt='8px'
            background='transparent'
            height='40px'
            borderColor='#dbe2ea'
            _hover={{
              borderColor: '#dbe2ea'
            }}
            {...props}
          />
        </Box>
      </AttachmentWrapperEditable>
    </div>
  )
}

export { LinkAttachmentDisplay, LinkAttachmentEditable }
